// eslint-disable-next-line no-redeclare,no-unused-vars
function checkInArray(value, array) {
  var pos = [];
  var neg = [];
  $.each(array, function (i, v) {
    if (!isString(v)) return;
    if (v.slice(0, 1) == '!') neg.push(v.slice(1));
    else pos.push(v);
  });
  if (pos.length && inArray(value, pos)) return true;
  else if (neg.length && !inArray(value, neg)) return true;
}
